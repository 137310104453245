export const config: { [index: string]: string } = {
  baseUrl: "https://website.johnsoncare.com",
  websiteTitle: "Loading website..."
};

export const metaConfigurations: { [index: string]: { title: string, [index: string]: string } }   = {
  "lccl.essexccl.com": {
    title: "Leicestershire County Care"
  },
  "avenuefarm.co.uk": {
    title: "Avenue Farm"
  },
  "avenuefarm.essexccl.com": {
    title: "Avenue Farm"
  },
  "kanandaconstruction.com": {
    title: "Kananda Construction"
  },
  "kananda.essexccl.com": {
    title: "Kananda Construction"
  },
  "rivenhalldevelopments.co.uk": {
    title: "Rivenhall Developments Ltd"
  }
};

export const siteConfigurations: { [index: string]: string } = {
  localhost: "default",
  "site.essexccl.com" : "essex",
  "lccl.essexccl.com": "lccl",
  "kananda.essexccl.com": "kananda",
  "kanandaconstruction.com": "kananda",
  "www.rivenhalldevelopments.co.uk": "rivenhall",
  "rivenhalldevelopments.co.uk": "rivenhall",
  "avenuefarm.co.uk": "avenuefarm",
  "avenuefarm.essexccl.com": "avenuefarm"
};
