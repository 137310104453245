
import { Component, Vue } from "vue-property-decorator";
import NavBar from "@/components/NavBar.vue"; // @ is an alias to /src
import Footer from "@/components/Footer.vue";
import { config } from "@/config";

@Component({
  components: {
    NavBar,
    Footer
  },
  mounted() {
    this.$store.dispatch("meta/fetchMetas");
  },
  watch: {
    $route(to, from) {
      document.title = to.meta.title || config.websiteTitle;
    }
  }
})
export default class App extends Vue {}
