
import Vue from "vue";
import Component from "vue-class-component";


@Component
export default class Footer extends Vue{
  get footer() {
    return this.$store.getters["meta/getState"]("footer", "");
  }
  get footerBackground() {
    return this.$store.getters["meta/getState"]("footerBackground", "blue");
  }
  get footerColor() {
    return this.$store.getters["meta/getState"]("footerColor", "white");
  }
}
