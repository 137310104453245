
import Vue from "vue";
import { listMenu } from "@/services/menu";
import { getWebHost } from "@/utils/api";
import Component from "vue-class-component";
import { Menu } from "@/models/menu";
import { Watch } from "vue-property-decorator";

const fetchMenus = async () => {
  return await listMenu();
};

@Component
export default class NavBar extends Vue {
  menus: Menu[] = [];
  activeSidebar = false;
  activeSlug = "";

  get title() {
    return this.$store.getters["meta/getState"]("title", getWebHost());
  }

  async mounted() {
    this.menus = await fetchMenus();
  }

  @Watch("$route")
  onSlugChanged(value: string, oldValue: string) {
    this.activeSidebar = false;
  }
}
